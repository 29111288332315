.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  background: rgb(39, 51, 60);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 15rem 7.2rem;
  padding-top: 80px;

  &__details {
    margin: 0 auto;
    text-align: center;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 10px;

    header {
      font-size: 1.7rem;
      max-width: 28rem;
      min-width: 28rem;
      font-weight: 600;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 1.5rem;
      background-color: rgb(40, 40, 40);
      color: white;
      border-radius: 0.2rem;
      position: relative;

      .sortIcon {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
        color: white;
        font-size: 1.5rem;
      }

      ul {
        color: #f0f0f0;
        list-style: none;
        font-size: 1.2rem;
        display: flex;
        flex-direction: column;
        font-weight: 500;
        line-height: 1.5;
        gap: 8px;

        .caliberItem {
          padding: 5px 0;
          user-select: none;  /* Prevent text selection */
          display: flex;
          align-items: flex-start;

          a {
            text-decoration: none;
            color: #f0f0f0;  /* Set the desired color */
            font-size: 1.5rem;
            display: inline-flex;
            align-items: center;
            background: none;  /* Ensure no background is applied */
            padding: 0;  /* Remove any padding */
            border: none;  /* Remove any border */
            outline: none;  /* Remove any outline */
            box-shadow: none;  /* Remove any box shadow */
            user-select: none;  /* Prevent text selection */

            &:hover {
              text-decoration: underline;
            }

            &:focus, &:active {
              outline: none;  /* Remove focus outline */
              box-shadow: none;  /* Remove focus box shadow */
              background: none;  /* Ensure no background on focus */
              color: inherit;  /* Ensure text color remains the same */
            }

            .caliberBullet {
              display: inline-block;
              width: 1rem;
              text-align: center;
              margin-right: 6px;
              color: inherit;
              user-select: none;  /* Prevent text selection */
            }
          }
        }
      }
    }
  }

  &__types {
    margin-top: 6rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4rem;
    justify-content: center;
  }

  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 52px;

    .ant-select {
      width: 100%;
      max-width: 300px;  // Set a maximum width to prevent resizing issues
      min-width: 300px;  // Set a minimum width to keep it consistent
    }

    button {
      outline: none;
      border: none;
      padding: 16px 24px;
      font-size: 1.8rem;
      font-weight: 500;
      color: white;
      background-color: rgba(40, 40, 40);
      border-radius: 8px;
    }
  }

  &__type {
    flex: 1;
    color: white;
    width: 100%;
    min-height: 18rem;
    max-height: 18rem;
    background-color: #000;
    cursor: pointer;
    display: flex;
    max-width: 28rem;
    min-width: 28rem;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    font-size: 2.7rem;
    font-weight: 700;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: all 0.2s ease-out;
    background-color: rgba(40, 40, 40);

    &:hover {
      transform: scale(1.04);
    }
  }
}

@media only screen and (min-width: 1290px) {
  .home {
    padding: 15rem 1.2rem;
  }
}

@media only screen and (max-width: 800px) {
  .home__type {
    max-width: 100%;
    min-width: 100%;
    height: 24rem;
  }

  .home__types {
    flex-direction: column;

    section {
      header {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .home {
    padding: 12rem 4.2rem;
    padding-top: 16rem;

    &__types {
      margin-top: 8rem;
    }

    &__type {
      height: 19rem;
    }

    &__search {
      flex-direction: column;
      gap: 24px;
    }
  }
}


.home__search {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 52px;

  .antSelect {
    width: 100%;
    max-width: 300px;
    min-width: 300px; 
  }

  button {
    outline: none;
    border: none;
    padding: 16px 24px;
    font-size: 1.8rem;
    font-weight: 500;
    color: white;
    background-color: rgba(40, 40, 40);
    border-radius: 8px;
  }
}